import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const MainCopyright = (props) => {
  return (
    <>
      <section className="copyright bg-dark">
          <Container>
            <Row>
              <Col xs={12} md={6} className="text-center text-md-start">
                <Link to="/Article/terms-and-conditions">{props.lang === 'th' ? 'ข้อตกลงและเงื่อนไข' : 'Terms and Conditions'}</Link>
                <Link to="/Article/privacy-policy">{props.lang === 'th' ? 'นโยบายความเป็นส่วนตัว' : 'Privacy Policy'}</Link>
              </Col>
              <Col xs={12} md={6} className="text-center text-md-end">Copyright&copy; {new Date().getFullYear()}</Col>
            </Row>
          </Container>
        </section>
    </>
  )
}

export default MainCopyright