import React from 'react';
import LogoWhite from '../Images/Logo/logo-wt.webp';
import { Container, Row, Col } from 'react-bootstrap';
import { BiPhoneCall } from 'react-icons/bi';
import { FaTools, FaFacebook, FaInstagram, FaLine, FaFacebookMessenger, FaMapMarkerAlt, FaTwitter } from 'react-icons/fa';

function MainFooter({ lang, content }) {
  return (
    <>
      <section className='footer pt-5 bg-dark'>
        <Container className="pt-5">
          <Row>
            <Col xs={12} md={6} className="text-center text-md-start">
              <img src={LogoWhite} className="pe-4 pb-4" alt="logo volvo" style={{ width: '200px' }} />
              <ul className="social">
                {content.facebook ? (
                <li className="me-3">
                  <a href={content.facebook} target="_blank" rel="noreferrer">
                    <FaFacebook size={32} />
                  </a>
                </li>
                ) : ''}
                {content.twitter ? (
                <li className="me-3">
                  <a href={content.twitter} target="_blank" rel="noreferrer">
                    <FaTwitter size={32} />
                  </a>
                </li>
                ) : ''}
                {content.instagram ? (
                <li className="me-3">
                  <a href={content.instagram} target="_blank" rel="noreferrer">
                    <FaInstagram size={32} />
                  </a>
                </li>
                ) : ''}
                {content.line ? (
                <li className="me-3">
                  <a href={content.line} target="_blank" rel="noreferrer">
                    <FaLine size={32} />
                  </a>
                </li>
                ) : ''}
                {content.messenger ? (
                <li className="me-3">
                  <a href={content.messenger} target="_blank" rel="noreferrer">
                    <FaFacebookMessenger size={32} />
                  </a>
                </li>
                ) : ''}
                {content.location ? (
                <li className="me-3">
                  <a href={content.location} target="_blank" rel="noreferrer">
                    <FaMapMarkerAlt size={32} />
                  </a>
                </li>
                ) : ''}
              </ul>
            </Col>
            <Col xs={12} md={6} className="text-center">
              { content.address ? (
              <p className="mb-1">
                <FaMapMarkerAlt /> {content.address}
              </p>
              ) : ''}
              <p className="mb-5">
                <BiPhoneCall /> {content.phone}
                <span className="px-4">|</span>
                <FaTools /> {content['phone-service']}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

MainFooter.defaultProps = {
  lang: 'th',
  content: {}
}

export default MainFooter
