import React from 'react';
import { Container,Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PricingTable = (props) => {
  return (
    <>
      <section className="section section-car">
        <Container>
          <h2 className="text-center font-header">PRICE LIST</h2>
          <h4 className="text-center font-header mb-4">Volvo Car Thailand Product Retail Price List</h4>
          <Row>
            <Col>
              <table className='font-header w-100 table-pricing'>
                <thead>
                  <tr>
                    <th>Model</th>
                    <th style={{width: '140px'}}>Max.Power<br/><small>(hp/rpm)</small></th>
                    <th style={{width: '140px'}}>Max.Torque<br/><small>(Nm/rpm)</small></th>
                    <th style={{width: '140px'}}>Acceleration<br/><small>0-100 km/h(sec.)</small></th>
                    <th style={{width: '140px'}}>Elertric Range<br/><small>up to (km)</small></th>
                    <th style={{width: '140px'}}>MSRP<br/><small>(Thai Baht)</small></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-group">
                    <td colspan="100%">C-Range</td>
                  </tr>
                  <tr>
                    <td><b>C40 Recharge</b> Pure Elertric</td>
                    <td className='text-center'>408</td>
                    <td className='text-center'>660</td>
                    <td className='text-center'>4.7*</td>
                    <td className='text-center'>530*</td>
                    <td className="text-end">2,790,000</td>
                  </tr>
                  <tr className="table-group">
                    <td colspan="100%">S-Range</td>
                  </tr>
                  <tr>
                    <td><b>S60 Recharge</b> Ultimate T8 Plug-in Hybrid Dark</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>4.5*</td>
                    <td className='text-center'>90.2*</td>
                    <td className="text-end">2,640,000</td>
                  </tr>
                  <tr>
                    <td><b>S60 Recharge</b> Ultimate T8 Plug-in Hybrid Bright</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>4.6*</td>
                    <td className='text-center'>98.2*</td>
                    <td className="text-end">3,290,000</td>
                  </tr>
                  <tr className="table-group">
                    <td colspan="100%">V-Range</td>
                  </tr>
                  <tr>
                    <td><b>V60 Recharge</b> Plus T8 Plug-in Hybrid Dark</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>4.5*</td>
                    <td className='text-center'>90.2*</td>
                    <td className="text-end">2,340,000</td>
                  </tr>
                  <tr>
                    <td><b>V60 Recharge</b> Ultimate T8 Plug-in Hybrid Bright</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>4.5*</td>
                    <td className='text-center'>90.2*</td>
                    <td className="text-end">2,790,000</td>
                  </tr>
                  <tr className="table-group">
                    <td colspan="100%">XC-Range</td>
                  </tr>
                  <tr>
                    <td><b>XC40 Recharge</b> Pure Elertric</td>
                    <td className='text-center'>408</td>
                    <td className='text-center'>660</td>
                    <td className='text-center'>4.9*</td>
                    <td className='text-center'>500*</td>
                    <td className="text-end">2,690,000</td>
                  </tr>
                  <tr>
                    <td><b>XC40 Recharge</b> Plus T5 Plug-in Hybrid Dark</td>
                    <td className='text-center'>262</td>
                    <td className='text-center'>425</td>
                    <td className='text-center'>7.3*</td>
                    <td className='text-center'>44.0*</td>
                    <td className="text-end">2,190,000</td>
                  </tr>
                  <tr>
                    <td><b>XC40 Recharge</b> Ultimate T5 Plug-in Hybrid Bright</td>
                    <td className='text-center'>262</td>
                    <td className='text-center'>425</td>
                    <td className='text-center'>7.3*</td>
                    <td className='text-center'>44.0*</td>
                    <td className="text-end">2,490,000</td>
                  </tr>
                  <tr>
                    <td><b>XC60 Recharge</b> Plus T8 Plug-in Hybrid Dark</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>4.8*</td>
                    <td className='text-center'>88.7*</td>
                    <td className="text-end">3,090,000</td>
                  </tr>
                  <tr>
                    <td><b>XC60 Recharge</b> Ultimate T8 Plug-in Hybrid Dark</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>4.8*</td>
                    <td className='text-center'>88.7*</td>
                    <td className="text-end">3,590,000</td>
                  </tr>
                  <tr>
                    <td><b>XC60 Recharge</b> Ultimate T8 Plug-in Hybrid Bright</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>4.8*</td>
                    <td className='text-center'>88.7*</td>
                    <td className="text-end">3,590,000</td>
                  </tr>
                  <tr>
                    <td><b>XC90 Recharge</b> Plus T8 Plug-in Hybrid Dark</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>5.3*</td>
                    <td className='text-center'>76.7*</td>
                    <td className="text-end">4,090,000</td>
                  </tr>
                  <tr>
                    <td><b>XC90 Recharge</b> Ultimate T8 Plug-in Hybrid Bright</td>
                    <td className='text-center'>460</td>
                    <td className='text-center'>709</td>
                    <td className='text-center'>5.3*</td>
                    <td className='text-center'>76.7*</td>
                    <td className="text-end">4,690,000</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col className="text-end font-header mt-2 fs-6 fst-italic">{props.lang==='th'?'ราคานี้มีผลตั้งแต่ 01.01.2023':'Price effective from 01.01.2023'}</Col>
          </Row>
          <Row>
            <Col className="mt-3 mb-2 font-default fw-bold" md={{span: 8, offset: 2}}>{props.lang==='th'?'อ้างอิงผลการทดสอบที่ได้รับจากการทดสอบในห้องปฏิบัติการในประเทศไทยภายใต้สภาวะควบคุมของ UN ECE R101 (NEDC) ผลที่ได้รับจริงอาจแตกต่างกันไป ขึ้นอยู่กับปัจจัยต่างๆ เช่น อุณหภูมิแวดล้อม, ลักษณะการขับขี่, จำนวนผู้โดยสารในรถ เป็นต้น':'Refer to test result received from the lab in Thailand under UN ECE R101 (NEDC) controlled conditions. The actual result may vary depended on various factors such as ambient temperature, driving style, number of passenger in the car, etc.'}</Col>
          </Row>
          <Row>
            <Col className="mt-1 mb-1 font-default fw-bold" md={{span: 8, offset: 2}}>
              {props.lang==='th'?(
                <>
                  <>ราคาขายของรถยนต์วอลโว่ Recharge Plug-in Hybrid รวมบริการหลังการขายจากโรงงาน</>
                  <ul className="mb-0">
                    <li>บริการรับประกันคุณภาพ 3 ปี หรือ 100,000 กิโลเมตร (แล้วแต่ระยะใดถึงก่อน)</li>
                    <li>บริการให้ความช่วยเหลือ 24 ชั่วโมง นาน 1 ปี</li>
                    <li>รับประกันคุณภาพแบตเตอรี่แรงดันสูง 8 ปี หรือ 150,000 กิโลเมตร (แล้วแต่ระยะใดถึงก่อน)</li>
                  </ul>
                </>
              ):(
                <>
                  <>Price of Volvo Recharge Plug-in Hybrid includes factory after sales services</>
                  <ul className="mb-0">
                    <li>VOLVO WARRANTY 3 years or 100,000 km. (whichever comes first)</li>
                    <li>VOLVO ASSISTANT 24 hours for 1 year</li>
                    <li>Warranty on high-voltage battery for 8 years or 150,000 km. (whichever comes first)</li>
                  </ul>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="mt-0 mb-2 font-default fw-bold" md={{span: 8, offset: 2}}>{props.lang==='th'?
            (
              <>สอบถามรายละเอียดเกี่ยวกับราคารถ ข้อเสนอ และโปรแกรมบริการหลังการขายได้ <Link to='/ContactUs'>ที่นี่</Link></>
            ):(
              <>For more information on the car price, financial offer and optional after-sales service package. Please, contact <Link to='/ContactUs'>here</Link></>
            )}</Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default PricingTable