import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import { MdOutlineArticle } from 'react-icons/md';

const SectionArticle = ({ lang, content, homepage }) => {
  return (
    <>
      <section className="section section-event mb-5">
        {homepage ?
          <h2 className="text-center font-header mt-5 mb-4">{lang === 'th' ? 'ข่าวสารและกิจกรรม' : 'News & Event'}</h2>
          : ''}
        <Container>
          <Row>
            <Col md={12}>
              <Row>
                {content.map((item, index) => (
                  <>
                    {index === 0 ?
                      <Col xs={12} md={6}>
                        <Link to={'/Article/' + item.row_slug} className="tx-no-underline tx-color-dark">
                          <div className="blog-item">
                            <div className="blog-img bg-img ratio-1x1 mb-2" style={{ backgroundImage: "url('" + urlApi + '/storage/article/' + item.row_value + "')" }}></div>
                            <h4 className="px-2">{item.name}</h4>
                          </div>
                        </Link>
                      </Col>
                      : ''}
                  </>
                ))}
                <Col xs={12} md={6} className="home-event-list">
                  <Row>
                    {content.map((item, index) => (
                      <>
                        {index > 0 && index < 5 ?
                          <Col xs={12} md={6}>
                            <Link to={'/Article/' + item.row_slug} className="tx-no-underline tx-color-dark">
                              <div className="blog-item">
                                <div className="blog-img bg-img ratio-1x1 mb-2" style={{ backgroundImage: "url('" + urlApi + '/storage/article/' + item.row_value + "')" }}></div>
                                <h4 className="px-2 mb-3">{item.name}</h4>
                              </div>
                            </Link>
                          </Col>
                          : ''}
                      </>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row>
                {content.map((item, index) => (
                  <>
                    {index > 5 ?
                      <Col xs={12} md={3}>
                        <Link to={'/Article/' + item.row_slug} className="tx-no-underline tx-color-dark">
                          <div className="blog-item">
                            <div className="blog-img bg-img ratio-1x1 mb-2" style={{ backgroundImage: "url('" + urlApi + '/storage/article/' + item.row_value + "')" }}></div>
                            <h4 className="px-2 mb-3">{item.name}</h4>
                          </div>
                        </Link>
                      </Col>
                      : ''}
                  </>
                ))}
              </Row>

              {homepage ?
                <Row>
                  <Col className="text-center">
                    <Link to="/Article" className="tx-no-underline tx-color-dark">
                      <button className="btn btn-sm btn-outline-theme-black btn-sharp font-default px-5 mt-2">
                        <MdOutlineArticle /> {lang === 'th' ? 'อ่านทั้งหมด' : 'Read More'}
                      </button>
                    </Link>
                  </Col>
                </Row>
                : ''}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionArticle.defaultProps = {
  lang: 'th',
  content: [],
  homepage: true
}

export default SectionArticle
