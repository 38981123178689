import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import PageHeader from '../Components/PageHeader';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import SectionArticleContent from '../Components/SectionArticleContent';

const ArticleView = ({maincontent}) => {
  const params = useParams();
  const [content, setContent] = useState({
    item: {
      row_value: ''
    }, 
    contents: []
  });
  
  const location = useLocation();
  useEffect(() => {
    console.log("Article :: getItemBySlug ::", urlApi + "/?module=article&call=get-item-by-slug&slug=", params.slug);
    const url = '../' + urlApi + "/?module=article&call=get-item-by-slug&slug=" + params.slug;
    console.log(url);
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("Article :: getItemBySlug ::", result);
        setContent(result.results);
      });
  }, [location, params]);

  return (
    <>
      <MainNavbar />
      <PageHeader 
        label={content.item.name} 
        detail={content.item.detail} 
        backgroundImage={"../" + urlApi + '/storage/article/' + content.item.row_value} 
      />
      <SectionArticleContent contents={content.contents} lang={maincontent.lang} path='../' />
      <MainFooter />
      <MainCopyright />
    </>
  )
}

export default ArticleView