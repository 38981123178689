import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import PageHeader from '../Components/PageHeader';
import SectionPromotion from '../Components/SectionPromotion';
import Photo1 from '../Images/Mockup/img_6.jpg';

const Promotion = ({contents}) => {
  const [content, setContent] = useState({
    item: {
      row_value: ''
    }, 
    contents: []
  });
  
  const location = useLocation();
  useEffect(() => {
    console.log("Article :: getItem ::", urlApi + "/?module=article&call=get-items&category=promotion");
    const url = urlApi + "/?module=article&call=get-items&category=promotion";
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("Article :: getItem ::", result);
        setContent(result.results);
      });
  }, [location]);

  return (
    <>
      <MainNavbar lang={content?.lang} />
      <PageHeader 
        label={contents?.lang==='th'?'โปรโมชั่น':'Promotion'} 
        detail={contents?.lang==='th'?'':''} 
        backgroundImage={Photo1} 
        classOverlay={'opacity-0'}
        textPosition={'top-0 start-0 ps-5 pt-5'}
      />
      <SectionPromotion lang={content?.lang} content={content?.items} homepage={false} />
      <MainFooter lang={content?.lang} />
      <MainCopyright lang={content?.lang} />
    </>
  )
}

Promotion.defaultProps = {
  contents: {
    lang: 'th'
  }
}

export default Promotion