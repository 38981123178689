import React from 'react';
import { urlApi } from '../Constants/Global';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

const SectionService = ({lang, content, homepage}) => {
  return (
    <>
      <Container>
        <section className="section">
          <h2 className="font-header text-center">{lang === 'th' ? 'บริการของเรา' : 'Our Service'}</h2>
          <p className="text-center">{lang === 'th' ? 'วอลโว่พระรามสอง ยินดีให้บริการและคำปรึกษา' : 'Volvo Rama 2, delighted to support and provide recommendations'}</p>
          <Row>
            {content.map(item => (
            <Col xs={12} md={4}>
              <Link to={"/Article/" + item.row_slug} className="tx-no-underline tx-color-dark">
                <div className="bg-img ratio-4x3 pos-relative" style={{ backgroundImage: "url('" + urlApi + '/storage/article/' + item.row_value + "')" }}>
                  <div className='deco-box-border'></div>
                </div>
                <h5 className='text-center text-uppercase font-header p-2'>{item.name}</h5>
              </Link>
            </Col>
            ))}
          </Row>
        </section>
      </Container>
    </>
  )
}

SectionService.defaultProps = {
  lang: 'th', 
  content: [], 
  homepage: true
}

export default SectionService
