import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import PageHeader from '../Components/PageHeader';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import SectionArticleContent from '../Components/SectionArticleContent';

const VPS = () => {
  const [content, setContent] = useState({
    item: {}, 
    contents: []
  });
  
  const location = useLocation();
  useEffect(() => {
    console.log("Article :: getItemBySlug ::", urlApi + "/?module=article&call=get-item-by-slug&slug=vps");
    const url = urlApi + "/?module=article&call=get-item-by-slug&slug=vps";
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("Article :: getItemBySlug ::", result);
        setContent(result.results);
      });
  }, [location]);

  return (
    <>
      <MainNavbar />
      <PageHeader 
        label={content.item.name} 
        detail={content.item.detail} 
        backgroundImage={urlApi + '/storage/article/' + content.item.row_value} 
      />
      <SectionArticleContent contents={content.contents} />
      <MainFooter />
      <MainCopyright />
    </>
  )
}

export default VPS