import React from 'react';
import { urlApi } from '../../Constants/Global';
import { Col } from 'react-bootstrap';

const SalesConsultant = ({ contents }) => {
  return (
    <>
      {contents.map((item, index) => (
        <Col md={3}>
          <div className="car-item bg-img ratio-1x1" style={{ backgroundImage: "url('" + urlApi + '/storage/media/' + item.set5 + "')" }}></div>
          <div className='text-center text-uppercase font-default fw-bold p-2 mb-4 bg-color-gray-light tx-color-black-light fs-7'>
            <span className="tx-ellipsis" title={item.set1}>{item.set1}</span>
            <small className="d-inline-block font-header w-100">{item.set2}</small>
          </div>
        </Col>
      ))}
    </>
  )
}

export default SalesConsultant