import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PageHeader = ({ label, detail, backgroundImage, classOverlay, classRatio, textPosition }) => {
  return (
    <>
      <section className={'page-header position-relative bg-img bg-light ' + classRatio} style={{backgroundImage: "url('"+backgroundImage+"')"}}>
        <div className={'overlay-bg bg-white ' + classOverlay}></div>
        {/* <Container className={"position-absolute " + textPosition}>
          <Row>
            <Col>
              <h1 className="fw-bold mb-0">{label}</h1>
              <h3 className='pb-0'>{detail}</h3>
            </Col>
          </Row>
        </Container> */}
      </section>
      <section className="pb-0">
        <Container>
          <Row>
            <Col sm={{span: 12, offset: 0}} md={{span: 8, offset: 2}} lg={{span: 6, offset: 3}}>
              <h1 className="fw-bold mb-0 text-center">{label}</h1>
              <h3 className='pb-0 text-center'>{detail}</h3>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

PageHeader.defaultProps = {
  label: '', 
  detail: '', 
  backgroundImage: null, 
  classOverlay: 'opacity-0', 
  classRatio: 'ratio-32x9', 
  textPosition: 'top-50 start-50 translate-middle'
}

export default PageHeader