import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { GrClose } from 'react-icons/gr';
import { urlApi } from '../Constants/Global';

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='popup-welcome'
    >
      <Modal.Header className='position-relative p-0'>
        <Modal.Title id="contained-modal-title-vcenter" className='w-100'>
          <div className='bg-img ratio-1x1' style={{ backgroundImage: 'url(' + urlApi + '/storage/media/' + props.src + ')' }}></div>
          <Button className='bg-transparent border-0 position-absolute top-0 end-0 m-2' onClick={props.onHide}>
            <GrClose size={25} />
          </Button>
          <Button className='view-detail font-default position-absolute bottom-0 start-50 translate-middle mb-2 p-0 px-5 rounded-pill bg-opacity-25' onClick={props.onHide}>
            {props.lang === 'th' ? 'ปิด' : 'Close'}
          </Button>
        </Modal.Title>
      </Modal.Header>
    </Modal>
  );
}

function PopupWelcome({ lang = 'th', show = false, src = '' }) {
  const [modalShow, setModalShow] = useState(show);

  useEffect(() => {
    // Listen for changes in eventData and show the modal accordingly
    if (show) {
      setModalShow(true);
    }
  }, [show]);

  return (
    <>
      <MyVerticallyCenteredModal
        src={src}
        show={modalShow}
        onHide={() => setModalShow(false)}
        lang={lang}
      />
    </>
  )
}

export default PopupWelcome