import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { urlApi } from '../Constants/Global';
import { MdOutlineArticle } from 'react-icons/md';

const SectionPromotion = ({ lang, content, homepage }) => {
  return (
    <>
      <section className="section section-event mb-5">
        {homepage ?
        <h2 className="text-center font-header mb-4">{lang === 'th' ? 'โปรโมชั่น' : 'Promotion'}</h2>
        : ''}
        <Container>
          <Row>
            <Col md={12}>
              <Row>
                {content.map(item => (
                  <Col xs={12} md={4}>
                    <div className="blog-item">
                      <div className="blog-img bg-img ratio-1x1 mb-2" style={{ backgroundImage: "url('" + urlApi + '/storage/article/' + item.row_value + "')" }}></div>
                      <h4 className='px-2'>{item.name}</h4>
                    </div>
                  </Col>
                ))}
              </Row>
              {homepage ? 
              <Row>
                <Col className="text-center">
                  <Link to="/PromotionCar" className="tx-no-underline tx-color-dark">
                    <button className="btn btn-sm btn-outline-theme-black btn-sharp font-default px-5 mt-2">
                      <MdOutlineArticle /> {lang === 'th' ? 'อ่านทั้งหมด' : 'Read More'}
                    </button>
                  </Link>
                </Col>
              </Row>
              : ''}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionPromotion.defaultProps = {
  lang: 'th',
  content: [], 
  homepage: true
}

export default SectionPromotion
