import React, { useState } from 'react';
import { urlApi } from '../Constants/Global';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container, Offcanvas } from 'react-bootstrap';
import { MdLanguage } from 'react-icons/md';
import { FaTools } from 'react-icons/fa';
import { GiSteeringWheel } from 'react-icons/gi';
import Logo from '../Images/Logo/logo.webp';

function MainNavbar(props) {
  const changeLanguage = (v_lang) => {
    console.log("componentDidMount", urlApi + "/?module=web&call=language-change", v_lang);
    var form_data = new FormData();
    form_data.append("lang", v_lang);

    const url = urlApi + "/?module=web&call=language-change";
    fetch(url, {
      method: 'POST', 
      body: form_data
    })
      .then(res => res.json())
      .then(result => {
        console.log("changeLanguage", result);
        window.location.reload();
      });
  }


  /** submenu */
  const [showService, setShowService] = useState(false);
  const [showCar, setShowCar] = useState(false);
  const [showUsedCar, setShowUsedCar] = useState(false);
  const [showPromotion, setShowPromotion] = useState(false);

  const handleMouseEnter = (event, targetMenu) => {
    if(targetMenu === 'service') {
      setShowService(true);
    }else if(targetMenu === 'car'){
      setShowCar(true);
    }else if(targetMenu === 'usedCar') {
      setShowUsedCar(true);
    }else if(targetMenu === 'promotion') {
      setShowPromotion(true);
    }
  };

  const handleMouseLeave = (event, targetMenu) => {
    if(targetMenu === 'service') {
      setShowService(false);
    }else if(targetMenu === 'car'){
      setShowCar(false);
    }else if(targetMenu === 'usedCar') {
      setShowUsedCar(false);
    }else if(targetMenu === 'promotion') {
      setShowPromotion(false);
    }
  };
  /** end - submenu */

  return (
    <>
      <Navbar expand="md" fixed="top" className="p-3 px-0">
        <Container fluid>
          <Navbar.Toggle aria-controls="offcanvasNavbar" />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel"></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center align-items-center flex-grow-1">
                <Nav.Link as={Link} to="/Home" className="d-block d-md-none" style={{width: '180px'}}><img src={Logo} className="logo" alt="Volvo Logo" /></Nav.Link>
                <Nav.Link as={Link} to="/">Home</Nav.Link>
                <NavDropdown title="New Car" id="basic-nav-dropdown" className="no-caret"
                  onMouseEnter={(event) => handleMouseEnter(event, 'car')}
                  onMouseLeave={(event) => handleMouseLeave(event, 'car')}
                  show={showCar}
                >
                  <NavDropdown.Item as={Link} to="/Model" className="fs-6">Model</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/PriceList" className="fs-6">Price List</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Sales" className="fs-6">Sales Consultant</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Volvo Selekt" id="basic-nav-dropdown" className="no-caret"
                  onMouseEnter={(event) => handleMouseEnter(event, 'usedCar')}
                  onMouseLeave={(event) => handleMouseLeave(event, 'usedCar')}
                  show={showUsedCar}
                >
                  <NavDropdown.Item as={Link} to="/UsedCar" className="fs-6">Search Used Cars</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Service" id="basic-nav-dropdown" className="no-caret"
                  onMouseEnter={(event) => handleMouseEnter(event, 'service')}
                  onMouseLeave={(event) => handleMouseLeave(event, 'service')}
                  show={showService}
                >
                  <NavDropdown.Item as={Link} to="/VPS" className="fs-6">Volvo Personal Service (VPS)</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/ExclusiveService" className="fs-6">Exclusive Service</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/ServiceAppointment" className="fs-6">Service Appointment</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/Home" className="d-none d-md-block" style={{width: '180px'}}><img src={Logo} className="logo" alt="Volvo Logo" /></Nav.Link>
                <NavDropdown title="Promotion" id="basic-nav-dropdown" className="no-caret"
                  onMouseEnter={(event) => handleMouseEnter(event, 'promotion')}
                  onMouseLeave={(event) => handleMouseLeave(event, 'promotion')}
                  show={showPromotion}
                >
                  <NavDropdown.Item as={Link} to="/PromotionCar" className="fs-6">New Car</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/PromotionUsedCar" className="fs-6">Volvo Selekt</NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/Article/service-campaign" className="fs-6">Service Campaign</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/Article">News/Event</Nav.Link>
                <Nav.Link as={Link} to="/AboutUs">About Us</Nav.Link>
                <Nav.Link as={Link} to="/ContactUs">Contact Us</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <div className="navi-optional d-none d-md-block">
        <ul>
          <li>
            <button className='btn btn-sm btn-outline-primary btn-sharp px-3 mt-2 d-inline-block w-auto' onClick={() => changeLanguage(props.lang === 'th' ? 'en' : 'th')}>
              <MdLanguage className='me-1' /> {props.lang === 'th' ? 'English' : 'ภาษาไทย'}
            </button>
          </li>
          <li>
            <Link to="/ServiceAppointment">
              <button className='btn btn-sm btn-theme btn-sharp px-3 mt-2 d-inline-block'>
                <GiSteeringWheel className='me-1' /> BOOK A TESTDRIVE
              </button>
            </Link>
          </li>
          <li>
            <Link to="/ServiceAppointment">
              <button className='btn btn-sm btn-theme btn-sharp px-3 mt-2 d-inline-block'>
                <FaTools className='me-1' /> MAKE A SERVICE APPOINTMENT
              </button>
            </Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default MainNavbar