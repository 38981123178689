import React from 'react';
import { urlApi } from '../Constants/Global';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const SectionCar = ({ content }) => {
  return (
    <>
      <section className="section section-car">
        <Container>
          <h2 className="text-center font-header">Volvo Cars</h2>
          {content.map(category => (
            <>
              <h3 className="text-center font-header mb-4">{category.name}</h3>
              <Row>
                {category.products.map(item => (
                  <Col xs={12} md={4}>
                    <Link to={'/Model/' + item.row_slug} className="tx-no-underline">
                      <div className="car-item bg-img ratio-16x9" style={{ backgroundImage: "url('" + urlApi + '/storage/product/' + item.row_value + "')" }}></div>
                      <h4 className='text-center text-uppercase font-header p-2 mb-4 bg-color-gray-light tx-color-black-light'>{item.name}</h4>
                    </Link>
                  </Col>
                ))}
              </Row>
            </>
          ))}
        </Container>
      </section>
    </>
  )
}

SectionCar.defaultProps = {
  content: []
}

export default SectionCar
