import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import PageHeader from '../Components/PageHeader';
import SectionForm from '../Components/SectionForm';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import Photo1 from '../Images/Mockup/img_6.jpg';

function Contact({lang}) {
  return (
    <>
      <MainNavbar />
      <PageHeader 
        label={lang==='th'?'นัดหมายเข้ารับบริการ':'Service Appointment'} 
        detail={lang==='th'?'':''} 
        backgroundImage={Photo1} 
        classOverlay={'opacity-0'}
        textPosition={'top-0 start-0 ps-5 pt-5'}
      />
      <SectionForm lang={lang} homepage={false} />
      <MainFooter />
      <MainCopyright />
    </>
  )
}

export default Contact