import React from 'react';
import { urlApi } from '../Constants/Global';
import { Container } from 'react-bootstrap';
import ContentDisplay from './ContentDisplay';

const SectionArticleContent = (props) => {
  const storage = props.path + urlApi + '/storage/article/';

  return (
    <>
      <section className="section section-event pt-0">
        <Container>
          <ContentDisplay contents={props.contents} storage={storage} lang={props.lang} />
        </Container>
      </section>
    </>
  )
}

SectionArticleContent.defaultProps = {
  contents: [], 
  path: '', 
  lang: 'th'
}

export default SectionArticleContent