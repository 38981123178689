import React, { useState } from 'react';
import { urlApi } from '../Constants/Global';
import { Row, Col, Form, Button, Modal } from 'react-bootstrap';
import FormPhone from './FormPhone';
import { BiCalendarCheck } from 'react-icons/bi';
import { GiSteeringWheel } from 'react-icons/gi';

const FormTestDrive = ({ lang }) => {
  const arrTime = [
    '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30',
    '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
    '15:00', '15:30', '16:00', '16:30', '17:00'
  ];
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    car: '',
    date: '',
    time: ''
  });
  const [modalOption, setModalOption] = useState({
    title: '',
    message: '',
    buttonLabel: 'ปิด',
    show: false
  });

  const handleInputChange = (event) => {
    console.log("handleInputChange", event);
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  }

  const handleSubmit = (event) => {
    console.log("handleSubmit", formData);
    event.preventDefault();

    if (formData.name === '' || formData.phone === '' || formData.car === '') {
      setModalOption({
        ...modalOption,
        title: lang === 'th' ? 'ดำเนินการไม่สำเร็จ' : 'Failed',
        message: lang === 'th' ? 'กรุณากรอกข้อมูลให้ครบถ้วน' : 'Please input all required form.',
        show: true
      });
    } else {

      var subject = "นัดหมายทดลองขับ";
      var message = "Model : " + formData.car + '\n';
      message += "วันที่นัดหมาย : " + formData.date + " " + formData.time + '\n';

      var form_data = new FormData();
      form_data.append("subject", subject);
      form_data.append("name", formData.name);
      form_data.append("phone", document.querySelector('.form-testdrive #phone').value);
      form_data.append("message", message);

      var url = urlApi + '/?module=web&call=message-submit';

      fetch(url, {
        method: 'POST',
        body: form_data
      })
        .then(res => res.json())
        .then(result => {
          console.log("messageSent", result.returnStatus.code, result);

          if (result.returnStatus.code === "0000") {
            setModalOption({
              ...modalOption,
              title: lang === 'th' ? 'ดำเนินการเรียบร้อย' : 'Successful',
              message: lang === 'th' ? <>
                <p>ได้รับข้อความเรียบร้อย ทีมงานของเราจะติดต่อกลับหาท่านโดยเร็วที่สุด</p>
                <p>* การนัดหมายจะสมบูรณ์เมื่อมีเจ้าหน้าที่ติดต่อกลับเพื่อยืนยันการนัดหมาย</p>
              </> : <>
                <p>Message received successfully. Our team will get back to you as soon as possible.</p>
                <p>* Appointments are complete when a representative contacts you to confirm the appointment.</p>
              </>,
              show: true
            });
            setFormData({
              name: '',
              phone: '',
              car: '',
              date: '',
              time: ''
            });
          } else {
            setModalOption({
              ...modalOption,
              title: lang === 'th' ? 'ดำเนินการไม่สำเร็จ' : 'Failed',
              message: lang === 'th' ? 'ขออภัยไม่สามารถส่งข้อความได้' : 'Sorry, unable to send the message.',
              show: true
            })
          }
        });
    }
  }

  const handleClose = () => {
    console.log("handleClose");
    setModalOption({
      ...modalOption,
      show: false
    })
  }

  return (
    <>
      <Form className='form-testdrive' onSubmit={handleSubmit}>
        <h2 className='text-center fw-normal mb-3'><GiSteeringWheel className="me-1" size={26} /> {lang === 'th' ? 'นัดหมายทดลองขับ' : 'BOOK A TESTDRIVE'}</h2>
        <h5 className='text-center fw-light mb-1'>
          {lang === 'th' ? 'วันและเวลาทำการ' : 'Business days and hours'}
        </h5>
        <h5 className='text-center fw-normal'>
          {lang === 'th' ? 'จันทร์ - อาทิตย์ 8:30 - 17:30' : 'Monday - Sunday 8.30 - 17.30'}
        </h5>
        <Row>
          <Col md={12}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="name"
                name="name"
                type="text"
                className="fw-bold"
                placeholder={lang === 'th' ? "ชื่อ-นามสกุล" : 'Name'}
                defaultValue={formData.name}
                value={formData.name}
                onChange={handleInputChange}
              />
              <label htmlFor="name" className="fw-bold">{lang === 'th' ? "ชื่อ-นามสกุล" : 'Name'}<span className="restrict"></span></label>
            </Form.Floating>
          </Col>
          <Col md={12}>
            <Form.Floating className="mb-3">
              <FormPhone
                id="phone"
                name="phone"
                type="text"
                className="fw-bold"
                placeholder={lang === 'th' ? "เบอร์โทรศัพท์" : 'Phone'}
                mask={'999-999-9999'}
              />
              <label htmlFor="phone" className="fw-bold">{lang === 'th' ? "เบอร์โทรศัพท์" : 'Phone'}<span className="restrict"></span></label>
            </Form.Floating>
          </Col>
          <Col md={12}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="car"
                name="car"
                type="text"
                className="fw-bold"
                placeholder={lang === 'th' ? 'รุ่นรถที่ต้องการทดลองขับ' : 'Model'}
                defaultValue={formData.car}
                value={formData.car}
                onChange={handleInputChange}
              />
              <label htmlFor="car" className="fw-bold">{lang === 'th' ? 'รุ่นรถที่ต้องการทดลองขับ' : 'Model'}<span className="restrict"></span></label>
            </Form.Floating>
          </Col>
          <Col xs={12} md={6}>
            <Form.Floating className="mb-3">
              <Form.Control
                id="date"
                name="date"
                type="date"
                className="fw-bold"
                placeholder={lang === 'th' ? 'วันที่' : 'Date'}
                defaultValue={formData.date}
                value={formData.date}
                onChange={handleInputChange}
              />
              <label htmlFor="date" className="fw-bold">{lang === 'th' ? 'วันที่ (นัดหมายล่วงหน้า 1 วัน)' : 'Date (Appointment 1 day in advance)'}</label>
            </Form.Floating>
          </Col>
          <Col xs={12} md={6}>
            <Form.Floating className="mb-3">
              <Form.Select
                id="time"
                name="time"
                type="time"
                className="fw-bold"
                placeholder={lang === 'th' ? 'เวลา' : 'Time'}
                defaultValue={formData.time}
                value={formData.time}
                onChange={handleInputChange}
              >
                <option>{lang === 'th' ? 'เลือกเวลา' : 'Choose Time'}</option>
                {arrTime.map((item, index) => (
                  <option value={item} key={index}>{item}</option>
                ))}
              </Form.Select>
              <label htmlFor="time" className="fw-bold">{lang === 'th' ? 'เวลา' : 'Time'}</label>
            </Form.Floating>
          </Col>
          <Col md={12} className="text-center">
            <h6 className='text-center fw-light mb-3'>{lang === 'th' ? '* การนัดหมายจะสมบูรณ์เมื่อมีเจ้าหน้าที่ติดต่อกลับเพื่อยืนยันการนัดหมาย' : '* Appointments are complete when a representative contacts you to confirm the appointment.'}</h6>
            <Button type="submit" className="btn btn-theme btn-sharp px-5 fw-bold">
              <BiCalendarCheck className="me-2" /> {lang === 'th' ? 'ยืนยันการนัดหมายทดลองขับ' : 'CONFIRM BOOKING A TESTDRIVE'}
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal size="sm" show={modalOption.show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="itx-primary">{modalOption.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="itx-dark">
          {modalOption.message}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn ibt-primary px-4" onClick={handleClose}>
            {modalOption.buttonLabel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

FormTestDrive.defaultProps = {
  lang: 'th'
}

export default FormTestDrive