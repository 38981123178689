import React from 'react';
import PageHeader from '../Components/PageHeader';
import SectionContact from '../Components/SectionContact';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import Photo1 from '../Images/Mockup/img_6.jpg';

const Contact = ({maincontent}) => {
  return (
    <>
      <MainNavbar />
      <PageHeader 
        label={maincontent?.lang==='th'?'ติดต่อเรา':'Contact Us'} 
        detail={maincontent?.lang==='th'?'Volvo Rama 2 ยินดีให้บริการ พบรถยนต์คุณภาพหลากหลายที่โชว์รูมของเรา':'Discover a wide range of quality vehicles at VOLVO Rama 2'} 
        backgroundImage={Photo1} 
      />
      <SectionContact lang={maincontent.lang} contents={maincontent.contact} />
      <MainFooter />
      <MainCopyright />
    </>
  )
}

export default Contact