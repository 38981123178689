import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function PageHeader(props) {
  return (
    <>
      <section>
        <Container>
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <Card>
                <Card.Body className="text-center p-5">สอบถามข้อมูล ติดต่อเราได้<Link to='/ContactUs'>ที่นี่</Link></Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default PageHeader