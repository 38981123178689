import React from 'react';
import { Container } from 'react-bootstrap';

function SectionVideo({content}) {
  return (
    <section>
      <Container>
        {content.map((item, index) => (
        <div className='position-relative w-100 ratio-16x9 mb-5' key={index}>
          <iframe className="position-absolute" width="100%" height="100%" src={item.set1} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
        ))}
      </Container>
    </section>
  )
}

SectionVideo.defaultProps = {
  content: []
}

export default SectionVideo