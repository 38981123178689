import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormService from './FormService';
import FormTestDrive from './FormTestDrive';

const SectionForm = (props) => {
  return (
    <>
      <section className={"section section-form p-3 p-md-5 pos-relative " + (props.backgroundImage ? 'bg-img' : 'tx-color')} style={props.backgroundImage ? { backgroundImage: "url('" + props.backgroundImage + "')" } : {}}>
        {props.backgroundImage ?
          <div className="overlay-black"></div>
          : ''}
        <Container className={props.homepage ? "overlay-content all-white" : 'overlay-content'}>
          <Row>
            <Col md={12} className="p-0 p-md-5">
              {props.homepage ?
                <h2 className="text-center font-header">Appointment</h2>
                : ''}
              <h4 className="text-center fw-light font-default mb-4">
                {props.lang === 'th' ? 'วอลโว่พระรามสอง ยินดีให้บริการและคำปรึกษา' : 'VOLVO Rama 2, We are pleased to provide services and advice.'}
                <br />
                {props.lang === 'th' ? 'ท่านสามารถกรอกฟอร์มเพื่อนัดหมายเข้าใช้บริการกับเรา เจ้าหน้าที่ของเราจะติดต่อกลับโดยเร็วที่สุด' : 'Please complete the form to book an appointment with us. Our staff will get back to you as soon as possible.'}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="px-0 px-md-5 mb-5 mb-md-0">
              <FormTestDrive lang={props.lang} />
            </Col>
            <Col xs={12} md={6} className="px-0 px-md-5 mb-5 mb-md-0">
              <FormService lang={props.lang} />
            </Col>
          </Row>
        </Container >
      </section >
    </>
  )
}

SectionForm.defaultProps = {
  homepage: true
}

export default SectionForm
