import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import PageHeader from '../Components/PageHeader';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import PopupPDPA from '../Components/PopupPDPA';
import PopupWelcome from '../Components/PopupWelcome';
import SectionArticleContent from '../Components/SectionArticleContent';

const ModelView = () => {
  const params = useParams();
  const [content, setContent] = useState({
    item: {
      row_value: ''
    }, 
    contents: []
  });
  
  const location = useLocation();
  useEffect(() => {
    console.log("Product :: getItemBySlug ::", urlApi + "/?module=product&call=get-item-by-slug&slug=", params.slug);
    const url = '../' + urlApi + "/?module=product&call=get-item-by-slug&slug=" + params.slug;
    console.log(url);
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("Product :: getItemBySlug ::", result);
        setContent(result.results);
      });
  }, [location, params]);

  return (
    <>
      <MainNavbar />
      <PageHeader 
        label={content.item.name} 
        detail={content.item.detail} 
        backgroundImage={"../" + urlApi + '/storage/product/' + content.item.row_value} 
        classOverlay={'opacity-0'}
        classRatio={'ratio-21x9'} 
        textPosition={'top-0 start-0 ps-5 pt-5'}
      />
      <SectionArticleContent contents={content.contents} path='../' />
      <MainFooter />
      <MainCopyright />
    </>
  )
}

export default ModelView