import React from 'react';
import { urlApi } from '../../Constants/Global';
import { Row, Col } from 'react-bootstrap';
import { FaRegEnvelope } from 'react-icons/fa';
import { FiPhone } from 'react-icons/fi';

const Vps = ({ contents }) => {
  return (
    <>
      {contents.map((item, index) => (
        <Col md={{ span: (index === 0 ? 6 : 6), offset: (index === 0 ? 3 : 0) }} className="mb-4 px-4">
          <Row>
            <Col xs={4} className='px-0'>
              <div className="car-item bg-img ratio-3x4" style={{ backgroundImage: "url('" + urlApi + '/storage/media/' + item.set5 + "')" }}></div>
            </Col>
            <Col xs={8} className="text-uppercase font-default fw-bold p-4 bg-color-gray-light tx-color-black-light">
              <h5 className="mb-0 tx-ellipsis">{item.set1}</h5>
              <h5 className="d-inline-block w-100 tx-ellipsis">{item.set2}</h5>
              <span>
                {(item.email !== '' ? (
                  <a href={'mailto:' + item.set3} className="tx-ellipsis text-decoration-none tx-color-primary fs-6">
                    <FaRegEnvelope className="me-2" /> {item.set3}
                  </a>
                ) : '')}
                {(item.phone !== '' ? (
                  <a href={'tel:' + item.set4} className="tx-ellipsis text-decoration-none tx-color-primary fs-6">
                    <FiPhone className="me-2" /> {item.set4}
                  </a>
                ) : '')}
              </span>
            </Col>
          </Row>
        </Col>
      ))}
    </>
  )
}

Vps.defaultProps = {
  contents: []
}

export default Vps