import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import PageHeader from '../Components/PageHeader';
import PageComingSoon from '../Components/PageComingSoon';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import Photo1 from '../Images/Mockup/used-car.jpg';

function UsedCar() {
  const [content, setContent] = useState({
    lang: 'th', 
    webuser: 'guest',
    contact: {
      address: '',
      phone: '',
      facebook: ''
    }, 
    content: {}
  });
  
  const location = useLocation();
  useEffect(() => {
    console.log("App :: getContent ::", urlApi + "/?module=web&call=main-get-content");
    const url = urlApi + "/?module=web&call=main-get-content";
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("App", result);
        setContent(result.results);
      });
  }, [location]);

  return (
    <>
      <MainNavbar />
      <PageHeader 
        label={content?.lang==='th'?'Volvo Selekt Approved Used Cars':'Volvo Selekt Approved Used Cars'} 
        detail={content?.lang==='th'?'':''} 
        backgroundImage={Photo1} 
        textPosition={'top-0 start-0 ps-5 pt-5'}
      />
      <PageComingSoon lang={content?.lang} />
      <MainFooter />
      <MainCopyright />
    </>
  )
}

export default UsedCar