import React from 'react';
import { urlApi } from '../Constants/Global';
import { Container, Carousel } from 'react-bootstrap';
import LogoWhite from '../Images/Logo/logo-wt.webp';

const SectionCarousel = ({ content }) => {
  return (
    <>
      <Container fluid className="px-0">
        <Carousel>

          {content.map(item => (
            <Carousel.Item className='position-relative'>
              <div className='bg-img ratio-21x9' style={{ backgroundImage: 'url(' + urlApi + '/storage/slide/' + item.row_value + ')' }}></div>
              {item.row_caption === '1' ? 
              <Carousel.Caption className={"p-5 text-center position-absolute " + item.row_align}>
                <img src={LogoWhite} style={{ width: '200px' }} alt="Slide logo" className="mt-5 mb-4" />
                <h1 className="font-header mb-1">{item.name}</h1>
                <h4 className="px-4 mb-0">{item.detail}</h4>
              </Carousel.Caption>
              : ''}
            </Carousel.Item>
          ))}

        </Carousel>
      </Container>
    </>
  )
}

SectionCarousel.defaultProps = {
  content: []
}

export default SectionCarousel