import React from 'react';
import { Link } from 'react-router-dom';

const PopupPDPA = (props) => {
  const handleClose = () => {
    props.updateShowStatus(false);
  }

  return (
    <>
      <div className='popup-pdpa position-fixed bottom-0 end-0 m-4 p-4 text-center' style={{display: (props.show ? 'block' : 'none')}}>
        <p>{props.lang === 'th' ? 'เราใช้คุกกี้เพื่อมอบประสบการณ์การใช้งานที่ดีที่สุดบนเว็บไซต์ของเรา รวมทั้งเพื่อปรับเนื้อหาและโฆษณาให้เหมาะสมกับบุคคล' : 'We use cookies to give you the best experience on our website. including to personalize content and advertisements'}</p>
        <p>{props.lang === 'th' ? 'อ่านเพิ่มเติมได้ที่ ' : 'Read more at '}
          <Link to='/Article/privacy-policy'>{props.lang === 'th' ? 'นโยบายความเป็นส่วนตัว' : 'Privacy Policy'}</Link>
        </p>
        <button className="btn rounded-pill p-0 px-4" onClick={handleClose}>{props.lang === 'th' ? 'ยอมรับ' : 'Accept'}</button>
      </div>
    </>
  )
}

PopupPDPA.defaultProps = {
  show: false
}

export default PopupPDPA