import React from 'react';
import { Form } from 'react-bootstrap';
import InputMask from 'react-input-mask';

const FormPhone = (props) => {
  return (
    <InputMask mask={props.mask} maskChar={'_'}>
      {() => <Form.Control type='text' id="phone" />}
    </InputMask>
  );
};

export default FormPhone;