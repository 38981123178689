import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleMaps = (props) => {
  const [mapKey, setMapKey] = useState(0);
  const location = useLocation();

  useEffect(() => {
    // Increment the mapKey to trigger a re-render
    setMapKey((prevKey) => prevKey + 1);
  }, [location]);

  return (
    <iframe
      key={mapKey}
      src={props.src}
      width="100%"
      height="450"
      allowFullScreen=""
      aria-hidden="false"
      tabIndex="0" 
      title="Google Maps"
    ></iframe>
  );
};

GoogleMaps.defaultProps = {
  src: ''
}

export default GoogleMaps;
