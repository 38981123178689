import React from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';

const FormTime = ({ lang }) => {
  const arrTime = [
    '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30',
    '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
    '15:00', '15:30', '16:00', '16:30', '17:00', '17:30'
  ]

  return (
    <FloatingLabel controlId="floatingSelectGrid" label={lang === 'th' ? 'เวลา' : 'Time'}>
      <Form.Select aria-label={lang === 'th' ? 'เลือกเวลา' : 'Choose Time'}>
        <option>{lang === 'th' ? 'เลือกเวลา' : 'Choose Time'}</option>
        {arrTime.map((item, index) => (
          <option value={item} key={index}>{item}</option>
        ))}
      </Form.Select>
    </FloatingLabel>
  );
};

FormTime.defaultProps = {
  lang: 'th'
}

export default FormTime;