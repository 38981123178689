import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import PageHeader from '../Components/PageHeader';
import SectionCar from '../Components/SectionCar';
import Photo1 from '../Images/Mockup/ex30.png';

const Model = ({contents}) => {
  const [content, setContent] = useState({
    item: {
      row_value: ''
    }, 
    contents: []
  });
  
  const location = useLocation();
  useEffect(() => {
    console.log("Model :: getItem ::", urlApi + "/?module=product&call=get-items");
    const url = urlApi + "/?module=product&call=get-items";
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("Model :: getItem ::", result);
        setContent(result.results);
      });
  }, [location]);

  return (
    <>
      <MainNavbar lang={content?.lang} />
      <PageHeader 
        label={content?.lang==='th'?'Model':'Model'} 
        detail={content?.lang==='th'?'':''} 
        backgroundImage={Photo1} 
        classOverlay={'opacity-0'}
        classRatio={'ratio-21x9'} 
      />
      <SectionCar lang={content?.lang} content={content?.items} />
      <MainFooter lang={content?.lang} />
      <MainCopyright lang={content?.lang} />
    </>
  )
}

Model.defaultProps = {
  contents: {
    lang: 'th'
  }
}

export default Model