import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../Images/Logo/logo.webp';
import FormContact from './FormContact';
import { BiPhoneCall } from 'react-icons/bi';
import { FaMapMarkerAlt, FaTools, FaFacebook, FaInstagram, FaLine, FaFacebookMessenger } from 'react-icons/fa';
import GoogleMaps from './GoogleMaps';

const SectionContact = ({contents, lang}) => {
  console.log("SectionContact", contents);
  return (
    <>
      <section>
        <Container className="pb-5">
          <Row>
            <Col md={12} className="text-center pt-5 pb-5">
              <img src={Logo} alt="logo" style={{width: '240px'}} className='mb-4' />
              {contents.address ? 
              <p className="mb-1">
                <FaMapMarkerAlt /> {contents.address}
              </p>
              : ''}
              <p className="mb-4">
                {contents.phone ? 
                <><BiPhoneCall /> {contents.phone}</>
                : ''}
                {contents["phone-service"] ? 
                <>
                  <span className="px-4">|</span>
                  <FaTools /> {contents["phone-service"]}
                </>
                : ''}
              </p>
              <ul className="social mb-5">
                {contents.facebook ? 
                <li className="me-3">
                  <a href={contents.facebook} target="_blank" rel="noreferrer">
                    <FaFacebook size={32} />
                  </a>
                </li>
                : ''}
                {contents.instagram ? 
                <li className="me-3">
                  <a href={contents.instagram} target="_blank" rel="noreferrer">
                    <FaInstagram size={32} />
                  </a>
                </li>
                : ''}
                {contents.line ? 
                <li className="me-3">
                  <a href={contents.line} target="_blank" rel="noreferrer">
                    <FaLine size={32} />
                  </a>
                </li>
                : ''}
                {contents.messenger ? 
                <li className="me-3">
                  <a href={contents.messenger} target="_blank" rel="noreferrer">
                    <FaFacebookMessenger size={32} />
                  </a>
                </li>
                : ''}
                {contents.location ? 
                <li className="me-3">
                  <a href={contents.location} target="_blank" rel="noreferrer">
                    <FaMapMarkerAlt size={32} />
                  </a>
                </li>
                : ''}
              </ul>
            </Col>
            <Col xs={12} md={7} className="px-4">
              {contents["google-maps"] ?
              <GoogleMaps src={contents["google-maps"]} />
              : ''}
            </Col>
            <Col xs={12} md={5} className="px-4 pt-4 pb-4">
              <FormContact lang={lang} />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

SectionContact.defaultProps = {
  lang: 'th', 
  contents: {}
}

export default SectionContact