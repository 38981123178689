import React, { useState, useEffect } from 'react';
import { urlApi } from './Constants/Global';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'quill/dist/quill.snow.css';
import './Styles/main.css';
import UnderDevelop from './Pages/UnderDevelop';
import Home from './Pages/Home';
import Model from './Pages/Model';
import ModelView from './Pages/ModelView';
import Contact from './Pages/Contact';
import PriceList from './Pages/PriceList';
import Sales from './Pages/Sales';
import VPS from './Pages/VPS';
import AboutUs from './Pages/AboutUs';
import Article from './Pages/Article';
import ArticleView from './Pages/ArticleView';
import UsedCar from './Pages/UsedCar';
import ServiceAppointment from './Pages/ServiceAppointment';
import Promotion from './Pages/Promotion';
import ExclusiveService from './Pages/ExclusiveService';
import PopupPDPA from './Components/PopupPDPA';
import PopupWelcome from './Components/PopupWelcome';

const App = () => {
  const [contents, setContents] = useState({
    lang: 'th', 
    popup_pdpa: false, 
    popup_welcome: false, 
    contact: {
      address: '',
      email: '', 
      facebook: '', 
      'google-maps': '', 
      instagram: '', 
      line: '', 
      'line-link': '', 
      'line-qrcode': '', 
      location: '', 
      messenger: '', 
      phone: '',
      'phone-service': '', 
      twitter: '', 
      'work-hours': ''
    },
  });

  useEffect(() => {
    console.log("componentDidMount", urlApi + "/?module=web&call=main-get-content");
    const url = urlApi + "/?module=web&call=main-get-content";
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("App", result);
        setContents(result.results);
      });
  }, []);

  const showPDPA = (data) => {
    const url = urlApi + "/?module=web&call=popup-pdpa-change";
    fetch(url)
      .then(res => res.json())
      .then(result => {
        
      });
    setContents({
      ...contents, 
      popup_pdpa: false, 
      popup_welcome: true
    });
  }

  const showWelcome = (data) => {
    const url = urlApi + "/?module=web&call=popup-welcome-change";
    fetch(url)
      .then(res => res.json())
      .then(result => {
        
      });
    setContents({
      ...contents, 
      popup_welcome: false
    });
  }
  
  return (
    <>
      <BrowserRouter basename={''}> {/* '/projects/volvo/' */}
        <div className="wrapper">
          <Routes>
            <Route path="/" element={<Home maincontent={contents} />} />
            <Route path="/Home" element={<Home maincontent={contents} />} />
            <Route path="/Model" element={<Model />} />
            <Route path="/Model/:slug" element={<ModelView />} />
            <Route path="/PriceList" element={<PriceList />} />
            <Route path="/Sales" element={<Sales />} />
            <Route path="/UsedCar" element={<UsedCar />} />
            <Route path="/VPS" element={<VPS />} />
            <Route path="/ExclusiveService" element={<ExclusiveService />} />
            <Route path="/ServiceAppointment" element={<ServiceAppointment />} />
            <Route path="/PromotionCar" element={<Promotion />} />
            <Route path="/PromotionUsedCar" element={<UsedCar />} />
            <Route path="/Article" element={<Article maincontent={contents} />} />
            <Route path="/Article/:slug" element={<ArticleView maincontent={contents} />} />
            <Route path="/AboutUs" element={<AboutUs maincontent={contents} />} />
            <Route path="/ContactUs" element={<Contact maincontent={contents} />} />
          </Routes>
        </div>
        <PopupPDPA lang={contents?.lang} show={contents?.popup_pdpa} updateShowStatus={showPDPA} />
        <PopupWelcome lang={contents?.lang} src={contents?.welcome_image} show={contents?.popup_welcome}  updateShowStatus={showWelcome} />
      </BrowserRouter>
    </>
  );
}

export default App;
