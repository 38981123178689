import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { urlApi } from '../Constants/Global';
import PhotoWelcome from '../Images/Mockup/4.png';
import MainNavbar from '../Components/MainNavbar';
import MainFooter from '../Components/MainFooter';
import MainCopyright from '../Components/MainCopyright';
import SectionCarousel from '../Components/SectionCarousel';
import SectionService from '../Components/SectionService';
import SectionPromotion from '../Components/SectionPromotion';
import SectionForm from '../Components/SectionForm';
import SectionCar from '../Components/SectionCar';
import SectionArticle from '../Components/SectionArticle';
import SectionVideo from '../Components/SectionVideo';

const Home = ({maincontent}) => {
  const [content, setContent] = useState({
    item: {}, 
    contents: []
  });
  
  const location = useLocation();
  useEffect(() => {
    console.log("Home :: getContent ::", urlApi + "/?module=home&call=get-content");
    const url = urlApi + "/?module=home&call=get-content";
    fetch(url)
      .then(res => res.json())
      .then(result => {
        console.log("Home :: getContent ::", result);
        setContent(result.results);
      });
  }, [location]);

  return (
    <>
      <MainNavbar lang={maincontent?.lang} />
      <SectionCarousel lang={maincontent?.lang} content={content?.slides} />
      <SectionCar lang={maincontent?.lang} content={content?.products} />
      <SectionVideo content={content?.highlights} />
      <SectionService lang={maincontent?.lang} content={content?.services} />
      <SectionPromotion lang={maincontent?.lang} content={content?.promotions} />
      <SectionArticle lang={maincontent?.lang} content={content?.articles} homepage={true} />
      <SectionForm lang={maincontent?.lang} backgroundImage={PhotoWelcome} />
      <MainFooter lang={maincontent?.lang} content={maincontent?.contact} />
      <MainCopyright lang={maincontent?.lang} />
    </>
  )
}

export default Home