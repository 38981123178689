import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { PiDownloadSimpleBold } from 'react-icons/pi';

function ContentDownload({src, lang}) {
  return (
    <>
      <Col className="text-center">
        <a href={src} target='_blank' rel='noreferrer'>
          <Button className="btn btn-sm btn-theme btn-sharp btn-download px-5 mt-2 d-inline-block">
            <PiDownloadSimpleBold className="me-2" />
            {lang === 'th' ? 'ดาวน์โหลดโบรชัวร์' : 'Download Brochure'}
          </Button>
        </a>
      </Col>
    </>
  )
}

ContentDownload.defaultProps = {
  src: '', 
  lang: 'th'
}

export default ContentDownload